.quizQuestionCont {
    width: 100%;
    height: 100%;
    max-height: 550px;
    background: white;
    display: flex;
    flex-direction: column;
    .quizQuestionLoadContainer {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .horImageCont {
        max-height: 150px;
        margin: 10px;
        img {
            width: 100%;
            position: relative;
        }
    }
    .quizQuestionContent {
        display: none;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        .quizQuestionHeaders {
            text-align: center;
            font-size: 14px;
            padding-top: 30px;
            @media screen and (max-width: 780px) {
                font-size: 2vw;
            }
            @media screen and (max-width: 588px) {
                font-size: 11px;
            }
        }
        .quizQuestionActualCont {
            font-size: 20px;
            font-weight: 600;
            .questionText {
                color: #e97301;
            }
            @media screen and (max-width: 780px) {
                font-size: 2.1vw;
            }
            @media screen and (max-width: 588px) {
                font-size: 12px;
            }
        }
    }
    .quizOptionsContent {
        padding: 20px;
        flex-direction: column;
        .questionNumber {
            font-size: 14px;
            font-weight: 600;
            @media screen and (max-width: 780px) {
                font-size: 2vw;
            }
            @media screen and (max-width: 588px) {
                font-size: 11px;
            }
            color: #e97301;
        }
        .questionText {
            font-size: 19px;
            @media screen and (max-width: 780px) {
                font-size: 2vw;
            }
            @media screen and (max-width: 588px) {
                font-size: 12px;
            }
        }
        .questionOptionsList {
            padding-left: 10px;
            padding-top: 30px;
            font-weight: 600px;
            label {
                padding-left: 10px;
            }
        }
        .button_group {
            display: flex;
            div {
                user-select: none;
                padding: 10px;
                margin: 5px;
                &:hover,
                &:focus {
                    box-shadow: 2px 2px 5px 2px rgba(51, 51, 51, 0.5);
                }
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .submit_button {
                background-color: #2a3852;
                color: white;
                pointer-events: none;
                &.enabled {
                    pointer-events: all;
                    background-color: #001b50;
                }
            }
        }
    }
    .quizAnswerContent {
        display: flex;
        height: 100%;
        padding: 10px;
        &.smaller {
            height: 65%;
        }
        @media screen and (max-width: 988px) {
            flex-direction: column;
            height: 100%;
            &.smaller {
                height: calc(100% - 150px);
            }
        }
        .quizTextContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .quizQuestionHeaders {
                text-align: center;
                font-size: 16px;
                @media screen and (max-width: 780px) {
                    font-size: 2vw;
                }
                @media screen and (max-width: 588px) {
                    font-size: 12px;
                }
            }
            .quizQuestionActualCont {
                font-weight: 600;
                font-size: 20px;
                @media screen and (max-width: 780px) {
                    font-size: 2.1vw;
                }
                @media screen and (max-width: 588px) {
                    font-size: 12px;
                }
                .questionNumber {
                    padding-top: 40px;
                    font-weight: 600;
                    font-size: 14px;
                    @media screen and (max-width: 780px) {
                        font-size: 2vw;
                    }
                    @media screen and (max-width: 588px) {
                        font-size: 11px;
                    }
                    color: #e97301;
                }
                .questionText {
                    font-size: 19px;
                    @media screen and (max-width: 780px) {
                        font-size: 2vw;
                    }
                    @media screen and (max-width: 588px) {
                        font-size: 11px;
                    }
                }
                .questionOptionsList {
                    padding-left: 10px;
                    padding-top: 30px;
                    font-weight: 600px;
                    label {
                        padding-left: 10px;
                    }
                    @media screen and (max-width: 588px) {
                        padding-left: 0px;
                        padding-top: 0px;
                    }
                }
            }
        }
        .quizAnswerImagesCont {
            display: flex;
            flex-direction: column;
            height: calc(100% - 20px);
            margin: 10px 0px;
            @media screen and (max-width: 988px) {
                flex-direction: row;
                overflow: hidden;
            }
            img {
                max-height: 50%;
                padding: 10px;
                max-width: 350px;
                min-height: unset;
                @media screen and (max-width: 988px) {
                    width: auto;
                    height: 100%;
                    min-height: 100%;
                    max-width: 48vw;
                }
                &.single {
                    max-height: 100%;
                }
            }
        }
        &.enlarged {
            .quizTextContainer {
                display: none;
            }
            .quizAnswerImagesCont {
                align-items: center;
                width: 100%;
                img {
                    max-width: unset;
                    max-height: unset;
                    height: 100%;
                }
            }
        }
        &.shrinkedImage {
            .quizTextContainer {
                display: flex;
            }
            .quizAnswerImagesCont {
                display: none;
            }
        }
    }
    .quizOptionResultCont {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 100%;
        font-size: 18px;
        @media screen and (max-width: 780px) {
            font-size: 12px;
            padding: 5px;
        }
        .questionNumber {
            padding: 0px 10px;
            height: 20px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #e97301;
        }
        .questionText {
            padding: 0px 10px;
            height: 20px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .totalVotes {
            padding: 10px 0px;
            text-align: center;
            height: 40px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .learnersText {
            color: #bfbfbf;
            text-align: end;
            padding-top: 20px;
        }
        .questionOptionsList {
            .singleQuestionOption {
                display: flex;
                justify-content: space-between;
                overflow: hidden;
                margin-top: 3px;
                .leftText {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    flex: 1;
                    .box {
                        width: 15px;
                        height: 15px;
                        max-width: 15px;
                        max-height: 15px;
                        border: 1px solid black;
                        margin: 0px 10px;
                        flex-shrink: 0;
                        &.filled {
                            background-color: black;
                        }
                    }
                    .tick,
                    .cross {
                        width: 12px;
                        max-width: 12px;
                        height: 12px;
                        max-height: 12px;
                        margin-top: -20px;
                        flex-shrink: 0;
                        margin-right: 5px;
                    }
                    .tick {
                        svg {
                            fill: red;
                            stroke-width: 3px;
                        }
                    }
                    .OptionText {
                        height: 20px;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        flex: 1;
                        line-height: 18px;
                        font-size: 18px;
                        padding-left: 5px;
                    }
                }
                .rightText {
                    display: flex;
                    min-width: 50%;
                    margin-left: 5px;
                    .scorebarCont {
                        flex: 1;
                        .scorebar {
                            display: block;
                            position: relative;
                            height: 100%;
                            background-color: #bfbfbf;
                            margin-left: auto;
                        }
                    }
                    .scoreText {
                        color: #bfbfbf;
                        width: 50px;
                        padding-left: 5px;
                    }
                }
            }
        }
        .button_group {
            display: flex;
            padding-top: 20px;
            @media screen and (max-width: 588px) {
                padding-top: 5px;
            }
            div {
                user-select: none;
                padding: 10px;
                margin: 5px;
                &:hover,
                &:focus {
                    box-shadow: 2px 2px 5px 2px rgba(51, 51, 51, 0.5);
                }
            }
            .skip_button {
                background: #001b50;
                color: white;
            }
        }
    }
}

video {
    outline: none;
    &.shrinked {
        @media screen and (max-width: 588px) {
            width: 100% !important;
            height: calc(40% - 30px) !important;
            top: 60% !important;
        }
        width: 40% !important;
        right: 0;
        left: unset !important;
    }
    &.shrinked2 {
        @media screen and (min-width: 588px) {
            padding: 10px 20px 10px 40px;
        }
        width: 40% !important;
        right: 0;
        height: 35% !important;
        left: unset !important;
    }
}

.quizOptionResultOuterCont {
    justify-content: center;
}

#startQuizbtn {
    img {
        box-shadow: 0px 0px 20px 2px rgb(51, 51, 51);
    }
}