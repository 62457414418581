.HTMLFullpageCard {
    z-index: 1000;
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    align-content: center;
    //border:2px solid red;
    overflow-x: auto;
    overflow-y: auto;
    opacity: 1;
    display: block;
}

.PollingFullpageCard {
    z-index: 2000;
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    align-content: center;
    //border:2px solid red;
    // overflow-x: auto;
    // overflow-y: auto;
    opacity: 1;
    display: block;
}

@media only screen and (max-width: 600px) {
    .PollingFullpageCard {
        height: 100vh;
        .modalcard {
            height: 100%;
            max-width: 100%;
            top: 0%;
            left: 0%;
            margin-left: 0px;
        }
    }
}